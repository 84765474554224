import React from "react"

import {
  PageBuilderVideo,
  PageBuilderCalloutCardWithImage,
  PageBuilderFeaturedContent,
  PageBuilderFeaturedText,
  PageBuilderContactDirectory,
  PageBuilderForm,
  PageBuilderCallToAction,
  PageBuilderFeaturedTextWithDefaults,
  PageBuilderStatistics,
  PageBuilderSpotlight,
  PageBuilderDefaultContent,
  PageBuilderCalloutCard,
  PageBuilderCode,
  PageBuilderContentBlock,
  PageBuilderFacts,
  PageBuilderFaq,
  PageBuilderPageHash,
  PageBuilderTable,
  PageBuilderTestimonial,
  PageBuilderTimeline,
  PageBuilderInfoBox,
  PageBuilderHero,
  PageBuilderImageGallery,
  PageBuilderFeaturedContentWithDefaults,
  PageBuilderRelatedStories as PageBuilderRelatedStoriesComponent,
  PageBuilderProductListing,
  PageBuilderPayment,
  PageBuilderGoFundMeCampaigns,
} from "@hurleymc/components"

import { Link } from "./link"
import { useStaticQuery, graphql } from "gatsby"
import { useSiteMetadata } from "../hooks/use-site-metadata"

const PageBuilderRelatedStories = (props) => {
  const { craft } = useStaticQuery(graphql`
    query RelateDStoriesQuery {
      craft {
        entries(
          limit: 4
          orderBy: "dateCreated ASC"
          section: ["spotlight", "testimonial"]
          site: "hurleyFoundation"
        ) {
          ...RelatedStories
        }
      }
    }
  `)
  // ! Have to map fields for the components
  craft.entries = craft.entries
    .map((r) => {
      switch (r.__typename) {
        case "Craft_spotlight_volunteer_Entry":
          r.__typename = "SpotlightVolunteer"
          break
        case "Craft_testimonial_testimonial_Entry":
          r.__typename = "Testimonial"
          break
      }
      return r
    })
    .filter((r) => r.__typename)
  return <PageBuilderRelatedStoriesComponent data={craft} {...props} />
}

export const getPropHtmlContent = (prop) => {
  if (prop && typeof prop !== "object") {
    return { content: prop }
  }
  return prop
}

const mapToNewGraphQlFields = (block) => {
  if (block.summary !== undefined) {
    block.summary = getPropHtmlContent(block.summary)
  }
  if (block.cta !== undefined && block.cta.length > 0) {
    block.cta = block.cta.map((cta) => ({
      ...cta,
      summary: getPropHtmlContent(cta.summary),
    }))
  }
  if (block.cards !== undefined && block.cards.length > 0) {
    block.cards = block.cards.map((cards) => ({
      ...cards,
      summary: getPropHtmlContent(cards.summary),
    }))
  }
  if (block.directory !== undefined && block.directory.length) {
    block.directory = block.directory.map((directory) => {
      if (typeof directory.phoneNumber !== "object") {
        directory.phoneNumber = {
          national: directory.phoneNumber,
          RFC3966: `tel:${directory.phoneNumber}`,
        }
      }
      return directory
    })
  }
  if (block.faqs !== undefined && block.faqs.length > 0) {
    block.faqs = block.faqs.map((faq) => ({
      ...faq,
      answer: getPropHtmlContent(faq.answer),
    }))
  }
  if (block.spotlight !== undefined && block.spotlight.length > 0) {
    block.spotlight = block.spotlight.map((spotlight) => ({
      ...spotlight,
      summary: getPropHtmlContent(spotlight.summary),
    }))
  }
  if (block.statistics !== undefined && block.statistics.length > 0) {
    block.statistics = block.statistics.map((statistics) => ({
      ...statistics,
      summary: getPropHtmlContent(statistics.summary),
    }))
  }
  if (block.timeline !== undefined && block.timeline.length > 0) {
    block.timeline = block.timeline.map((timeline) => ({
      ...timeline,
      summary: getPropHtmlContent(timeline.summary),
    }))
  }
  if (block.leftSummary !== undefined) {
    block.leftSummary = getPropHtmlContent(block.leftSummary)
  }
  if (block.rightSummary !== undefined) {
    block.rightSummary = getPropHtmlContent(block.rightSummary)
  }
  if (block.html !== undefined) {
    block.html = getPropHtmlContent(block.html)
  }
  if (block.answer !== undefined) {
    block.answer = getPropHtmlContent(block.answer)
  }
  if (block.quote !== undefined) {
    block.quote = getPropHtmlContent(block.quote)
  }
  if (block.contentBlock !== undefined) {
    block.contentBlock = getPropHtmlContent(block.contentBlock)
  }
  if (block.contentBody !== undefined) {
    block.contentBody = getPropHtmlContent(block.contentBody)
  }
  if (block.testimonial !== undefined && block.testimonial.length) {
    block.testimonial = block.testimonial.map((t) => ({
      ...t,
      quote: getPropHtmlContent(t.quote),
    }))
  }
  return block
}

const ProductListingBlock = ({ block, location }) => {
  const { siteUrl } = useSiteMetadata()
  const pathname =
    location && location.pathname && location.pathname !== "__home__"
      ? location.pathname
      : ""
  const pageUrl = `${siteUrl}/${pathname}`
  return (
    <PageBuilderProductListing
      key={`${block.id}-plb`}
      contentField={block.contentField}
      heading={block.heading}
      pageUrl={pageUrl}
      products={block.products}
    />
  )
}

export default ({ pageBuilder, location }) => {
  return pageBuilder.map((block) => {
    // ! Have to map fields for the components
    block = mapToNewGraphQlFields(block)
    switch (block.__typename) {
      case "Craft_pageBuilder_CalloutCard_BlockType":
        return (
          <PageBuilderCalloutCard
            components={{ Link }}
            key={block.id}
            leftBroughtToYouBy={block.leftBroughtToYouBy.map(
              ({ title, to }) => ({ title, to })
            )}
            leftHeader={block.leftHeader}
            leftIdentifier={block.leftIdentifier}
            leftLink={block.leftLink}
            leftLinkText={block.leftLinkText}
            leftSummary={block.leftSummary}
            rightBroughtToYouBy={block.rightBroughtToYouBy.map(
              ({ title, to }) => ({
                title,
                to,
              })
            )}
            rightHeader={block.rightHeader}
            rightIdentifier={block.rightIdentifier}
            rightLink={block.rightLink}
            rightLinkText={block.rightLinkText}
            rightSummary={block.rightSummary}
          />
        )
      case "Craft_pageBuilder_CalloutCardWithImage_BlockType":
        return (
          <PageBuilderCalloutCardWithImage
            broughtToYouBy={block.broughtToYouBy.map(({ title, to }) => ({
              title,
              to,
            }))}
            components={{ Link }}
            header={block.header}
            identifier={block.identifier}
            image={block.image}
            imagePosition={block.imagePosition}
            key={block.id}
            linkText={block.linkText}
            summary={block.summary}
            to={block.to}
          />
        )
      case "Craft_pageBuilder_CallToAction_BlockType":
        return (
          <PageBuilderCallToAction
            components={{ Link }}
            cta={block.cta}
            key={block.id}
          />
        )
      case "Craft_pageBuilder_Code_BlockType":
        return <PageBuilderCode html={block.html} key={block.id} />
      case "Craft_pageBuilder_ContactDirectory_BlockType":
        return (
          <PageBuilderContactDirectory
            identifier={block.identifier}
            key={block.id}
            directory={block.directory.map(
              ({
                contactImage,
                contactType,
                email,
                fullName,
                jobTitle,
                phoneNumber,
              }) => ({
                contactImage: contactImage,
                contactType: contactType.map(({ slug, title }) => ({
                  slug,
                  title,
                })),
                email,
                fullName,
                jobTitle,
                phoneNumber,
              })
            )}
          />
        )
      case "Craft_pageBuilder_ContentBlock_BlockType":
        return <PageBuilderContentBlock html={block.html} key={block.id} />
      case "Craft_pageBuilder_DefaultContent_BlockType":
        return (
          <PageBuilderDefaultContent
            components={{ Link }}
            cards={block.cards.map(
              ({ header, id, image, linkText, summary, to }) => ({
                header,
                id,
                image,
                linkText,
                summary,
                to,
              })
            )}
            identifier={block.identifier}
            key={block.id}
            linkText={block.linkText}
            linkHash={block.linkHash}
            to={block.to}
          />
        )
      case "Craft_pageBuilder_Facts_BlockType":
        return (
          <PageBuilderFacts components={{ Link }} key={block.id} {...block} />
        )
      case "Craft_pageBuilder_Faq_BlockType":
        return <PageBuilderFaq key={block.id} {...block} />
      case "Craft_pageBuilder_FeaturedContent_BlockType":
        return (
          <PageBuilderFeaturedContent
            components={{ Link }}
            graphic={block.graphic}
            header={block.header}
            identifier={block.identifier}
            key={block.id}
            linkText={block.linkText}
            summary={block.summary}
            to={block.to}
          />
        )
      case "Craft_pageBuilder_FeaturedContentWithDefaults_BlockType":
        return (
          <PageBuilderFeaturedContentWithDefaults
            components={{ Link }}
            cards={block.cards}
            graphic={block.graphic}
            header={block.header}
            identifier={block.identifier}
            key={block.id}
            linkText={block.linkText}
            summary={block.summary}
            to={block.to.map(({ title, uri }) => ({ title, uri }))}
          />
        )
      case "Craft_pageBuilder_FeaturedText_BlockType":
        return (
          <PageBuilderFeaturedText
            components={{ Link }}
            identifier={block.identifier}
            key={block.id}
            linkText={block.linkText}
            summary={block.summary}
            to={block.to.map(({ title, uri }) => ({ title, uri }))}
          />
        )
      case "Craft_pageBuilder_FeaturedTextWithDefaults_BlockType":
        return (
          <PageBuilderFeaturedTextWithDefaults
            cards={block.cards}
            components={{ Link }}
            identifier={block.identifier}
            key={block.id}
            summary={block.summary}
          />
        )
      case "Craft_pageBuilder_Form_BlockType":
        // ! Have to map fields for the components
        if (block.form && block.form.length > 0) {
          block.form = block.form.map((v) => {
            switch (v.__typename) {
              case "Craft_form_blackbaud_Entry":
                v.__typename = "FormBlackbaud"
                break
              case "Craft_form_blackbaudV2_Entry":
                v.__typename = "FormBlackbaudV2"
                break
              case "Craft_form_surveyGizmo_Entry":
                v.__typename = "FormSurveyGizmo"
                break
            }
            return v
          })
        }
        return <PageBuilderForm key={block.id} {...block} />
      case "Craft_pageBuilder_PageHash_BlockType":
        return <PageBuilderPageHash key={block.id} hash={block.hash} />
      case "Craft_pageBuilder_RelatedStories_BlockType":
        return (
          <PageBuilderRelatedStories
            components={{ Link }}
            identifier={block.identifier}
            key={block.id}
            location={location}
          />
        )
      case "Craft_pageBuilder_Spotlight_BlockType":
        return (
          <PageBuilderSpotlight
            broughtToYouBy={block.broughtToYouBy}
            components={{ Link }}
            identifier={block.identifier}
            key={block.id}
            spotlight={block.spotlight}
          />
        )
      case "Craft_pageBuilder_Statistics_BlockType":
        return (
          <PageBuilderStatistics
            components={{ Link }}
            identifier={block.identifier}
            key={block.id}
            statistics={block.statistics}
          />
        )
      case "Craft_pageBuilder_Table_BlockType":
        return <PageBuilderTable key={block.id} table={block.table} />
      case "Craft_pageBuilder_Testimonial_BlockType":
        return (
          <PageBuilderTestimonial
            components={{ Link }}
            key={block.id}
            testimonial={block.testimonial}
          />
        )
      case "Craft_pageBuilder_Timeline_BlockType":
        return (
          <PageBuilderTimeline
            identifier={block.identifier}
            key={block.id}
            timeline={block.timeline}
          />
        )
      case "Craft_pageBuilder_Video_BlockType":
        // ! Have to map fields for the components
        if (block.video && block.video.length > 0) {
          block.video = block.video.map((v) => {
            switch (v.__typename) {
              case "Craft_video_youTube_Entry":
                v.__typename = "VideoYouTube"
                break
            }
            return v
          })
        }
        return (
          <PageBuilderVideo
            components={{ Link }}
            header={block.header}
            identifier={block.identifier}
            key={block.id}
            linkText={block.linkText}
            summary={block.summary}
            to={block.to}
            video={block.video}
          />
        )
      case "Craft_pageBuilder_infoBox_BlockType":
        return (
          <PageBuilderInfoBox
            heading={block.heading}
            key={block.id}
            contentBlock={block.contentBlock}
            style={block.style}
          />
        )
      case "Craft_pageBuilder_hero_BlockType":
        return (
          <PageBuilderHero
            components={{ Link }}
            heading={block.heading}
            key={block.id}
            contentBody={block.contentBody}
            ctaLink={block.ctaLink}
            ctaText={block.ctaText}
            ctaStyle={block.ctaStyle}
            colorScheme={block.colorScheme}
            backgroundImage={block.backgroundImage}
          />
        )
      case "Craft_pageBuilder_imageGallery_BlockType":
        return (
          <PageBuilderImageGallery
            heading={block.heading}
            key={block.id}
            images={block.images}
          />
        )

      case "Craft_pageBuilder_paymentForm_BlockType":
        return (
          <PageBuilderPayment
            key={block.id}
            amountSuggestions={
              block.amountSuggestions && block.amountSuggestions.length
                ? block.amountSuggestions.map((s) => s.amount)
                : null
            }
            buttonText={block.buttonText}
            subheading={block.subheading}
            content={block.description}
            enableInHonorMemoryOf={block.enableInHonorMemoryOf}
            metadata={
              block.metadata && block.metadata.length
                ? block.metadata.reduce((acc, curr) => {
                    acc[curr.metadataKey] = curr.metadataValue
                    return acc
                  }, {})
                : null
            }
            productId={block.productId}
            productName={block.productName}
            successMessage={block.successMessage}
            stripeAccount={block.stripeAccount}
          />
        )

      case "Craft_pageBuilder_productListing_BlockType":
        return (
          <ProductListingBlock
            key={block.id}
            block={block}
            location={location}
          />
        )

      case "Craft_pageBuilder_gofundmeCampaigns_BlockType":
        return (
          <PageBuilderGoFundMeCampaigns
            key={block.id}
            heading={block.heading}
            campaigns={block.campaigns}
          />
        )

      default:
        console.error(`unknown component: ${block.__typename}`, block)
        return null
    }
  })
}
